import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// import Contains from "./Contains";
import Contains from "./Contains";
import HeaderVertical from "./Tabs/VerticalTab";
import HeaderHorizontal from "./Tabs/HorizontalTab";
import HeroBanner from "./HeroBanner";
import Chip from "@mui/material/Chip";
import { MdDone, MdInfo } from "react-icons/md";
import { Link, Button } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export const useContainerDimensions = (myRef) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const getDimensions = () => ({
      width: myRef.current.offsetWidth,
      // height: myRef.current.offsetHeight,
    });

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef]);

  return dimensions;
};

export default function VerticalTabs({ data, order, set_order }) {
  const [value, setValue] = React.useState(0);
  const [first_use_effect, set_first_use_effect] = React.useState(false);
  const componentRef = useRef();
  const company = JSON.parse(localStorage.getItem("company"));
  const { width, height } = useContainerDimensions(componentRef);

  const [isInstalled, setIsInstalled] = useState(true);

  useEffect(() => {
    const checkInstallation = () => {
      const installed = window.matchMedia("(display-mode: standalone)").matches;
      setIsInstalled(installed);
      // alert(installed)
    };
    const timer = setTimeout(checkInstallation, 1);
    const handleAppInstalled = () => {
      setIsInstalled(true);
    };
    window.addEventListener("appinstalled", handleAppInstalled);
    return () => {
      clearTimeout(timer);
      window.removeEventListener("appinstalled", handleAppInstalled);
    };
  }, []);

  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const handler = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
    };

    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("beforeinstallprompt", handler);
  }, []);

  const handleInstall = () => {
    // console.log(123);

    if (deferredPrompt) {
      // console.log(456);
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choice) => {
        if (choice.outcome === "accepted") {
          alert('Application installée')
          // console.log("L'utilisateur a accepté l'installation");
        }
        setDeferredPrompt(null);
      });
    }
  };
  // console.log(company?.status?.bgcolor);

  useEffect(() => {
    if (first_use_effect) {
      window.location.href = "#TabPanel-0";
      window.location.href = "#nori";
    }
    set_first_use_effect(true);
  }, [value])

  useEffect(() => {
    // Calcul Total && Filter products qte > 0
    const order_last = {
      ...order,
      total: Object.values(order?.products)
        .map((p) => p?.price * p?.qte)
        .reduce((a, b) => a + b, 0)
        .toFixed(2),
      products: Object.values(order?.products).filter((p) => p.qte > 0),
    };

    // Condition for update order
    if (
      order?.total !== order_last?.total ||
      order?.products?.length !== order_last?.products?.length
    )
      set_order(order_last);

    // Storage Order in Browser exceptio cas (reload page, exit browser) this function not work if clesring cache
    localStorage.setItem("order", JSON.stringify(order_last));
  }, [order]);

  return (
    <Box sx={{ p: 0, m: 0 }}>

      <Box sx={{ py: 0, px: 0, mx: 0, borderBottom: 0, borderColor: 'secondary.main' }}>
        <Box sx={{ height: 300, px: 0, mx: 0, display: "flex", justifyContent: "center", alignItems: "center", backgroundImage: 'url(/background.png)', backgroundSize: 1500 }}>
          <img src="logo.png" style={{ width: 400, width: 400, padding: '0 5 0 5' }} alt="Your Logo" color="secondary.light" />
          {!isInstalled && deferredPrompt &&
            <Button onClick={handleInstall} color="secondary" sx={{ textTransform: 'none', position: "absolute", top: 15, right: 15, }}>
              Install App
            </Button>
          }
        </Box>
      </Box>

      <Box>
        {company?.status_id == 1 &&
          <Chip icon={<MdInfo size={25} color={company?.status?.color} />} variant="outlined"
            sx={{ width: "100%", color: company?.status?.color, bgcolor: company?.status?.bgcolor, borderRadius: 0, border: 0 }}
            label={<Typography sx={{ fontSize: 15 }}>NOUS SOMMES ACTUELLEMENT FERMÉS.</Typography>}
          />
        }
        {company?.status_id == 2 &&
          <Chip icon={<MdInfo size={25} color={company?.status?.color} />} variant="outlined"
            sx={{ width: "100%", color: company?.status?.color, bgcolor: company?.status?.bgcolor, borderRadius: 0, border: 0, py: { xs: 3, sm: 0 } }}
            label={<Typography sx={{ fontSize: 15, whiteSpace: 'normal' }}>Désolés, nous ne prenons plus de commandes pour le moment. Merci.</Typography>}
          />
        }
        {company?.status_id == 3 &&
          <Box sx={{ borderBottom: 20, borderColor: "secondary.dark" }}></Box>
          // <Chip sx={{ borderBottom: 0, color: company?.status?.color, bgcolor: company?.status?.bgcolor, width: "100%", borderRadius: 0, border: 0 }} />
        }
      </Box>

      <Box id='nori' ref={componentRef} sx={{ flexGrow: 1, display: "flex", minHeight: height, bgcolor: "primary.main", backgroundImage: 'url(/background.png)', backgroundSize: 1500 }}>

        {/* Web Desktop */}
        <Box className="d-none d-sm-block">
          <HeaderVertical data={data} value={value} setValue={setValue} />
        </Box>
        <Box className="d-none d-sm-block">
          <Contains data={data} value={value} order={order} set_order={set_order} />
        </Box>

        {/* Web Mobile */}
        <Box className="d-block d-sm-none w-100" sx={{ p: 0, m: 0 }}>
          <HeaderHorizontal data={data} value={value} setValue={setValue} width={width} />
          <Contains data={data} value={value} order={order} set_order={set_order} />
        </Box>

      </Box>
    </Box >
  );
}
